@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.sendmail {
    display: flex;
    flex-direction: column;
    font-family: $font-book-regular, sans-serif;
    text-align: left;
}

.topMargin {
    margin-top: 2rem;
}

.availableColumnsList {
    display: flex;
    flex-direction: row;
}

.invalidPlaceholderGroup {
    color: $red-color;
}

.textField {
    width: 16rem;
}