@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.mainContainer {
    .redButton {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
        display: flex;
        width: fit-content;
    }

    .redButton:hover {
        text-decoration: none;
        color: $white-color;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
        border: none;
    }

    .redButton:disabled {
        color: #666565;
        background-color: $white-color;
        cursor: not-allowed;
    }

    .treeItemButton {
        color: $black-deep-color;
        background-color: $white-color;
        border: 1px solid $black-deep-color;
    }

    .buttonRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem 2rem 0 0;
    }

    .treeView {
        margin: 2rem 0 0 2rem;
        max-height: 80%;
        flex-grow: 1;
        min-width: 800px;
        width: 70%;
        max-width: 1200px;
        overflow-y: auto;

        .treeItem {
            border-left: 2px solid gray;
            padding: 0.5rem 0;
            text-align: left;

            .treeItemContent {
                display: grid;
                grid-template-columns: 1fr min-content max-content min-content;
                gap: 1rem;
                align-items: center;
            }
        }

        .treeItemRoot {
            margin-top: 1rem;
        }
    }

    .saveWarning {
        text-align: right;
        margin: 1rem 2rem 0 0;
        font-size: 20px;
        font-weight: bold;
    }
}
.dialog {
    .addForm {
        margin: 4rem 2rem;
    }

    .formControl {
        width: 100%;

        .error_span {
            color: $red-color;
            font-family: $font-book-regular, sans-serif;
        }
    }

    .redButton {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
        display: flex;
        width: fit-content;
    }

    .redButton:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .redButton:disabled {
        color: #666565;
        background-color: $white-color;
        cursor: not-allowed;
    }

    .dialogSaveButton {
        margin: 1rem 0 0 auto !important;
    }
}
