// Colors
$primary-color: #0c1f76;
$white-color: #ffffff;
$primary-button-color: #ffc60a;
$primary-text-color: #0c1f76;
$secondary-color: #7a86c0;
$border-color: #eeeeee;
$background-card: #ededed;
$black-low-color: #222222;
$button-disabled: #827f7f;
$red-color: #c50119;
$black-deep-color: #191919;
$black-light-color: #9d9d9c;

// Fonts
$font-black-regular: 'Gotham Black Regular';
$font-black: 'Gotham Medium';
$font-bold: 'Gotham Bold';
$font-bold-italic: 'Gotham Bold Italic';
$font-book-italic: 'Gotham Book Italic';
$font-book-regular: 'Gotham Book Regular';
$font-light: 'Gotham Light';
$font-medium: 'Gotham Medium';
