@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.dragAndDropForm {
    font-family: $font-medium;
    text-align: center;
    display: flex;
    justify-content: center;

    .dragAndDropCard {
        .dragAndDropContainer {
            border: solid 2px #d0d0d0;
            border-radius: 0.2em;

            padding: 0;
            background-color: $black-light-color;

            .dragAndDropHeader {
                padding: 0;
                color: white;
                min-height: 6em;

                .containerColumn {
                    min-height: 0.5em;
                    color: white;
                }
            }

            .dragAndDropBody {
                min-width: 25em;
                background-color: $white-color;
                overflow-y: auto;
                height: 45vh;
                padding: 0;
                margin: 0;

                .item {
                    padding: 10px;
                    border-radius: 3px;
                    border: solid 2px #d0d0d0;
                    background: #fff;
                    box-shadow: 0px 1px 2px 0px rgba(9, 30, 66, 0.25);
                    transition: background 0.1s;
                    cursor: pointer;
                    user-select: none;
                    align-items: center;
                    text-align: center;
                    min-width: 5rem;

                    @media (max-width: 1100px) {
                        padding: 10px 8px;
                    }
                    &:hover {
                        background: $red-color;
                    }
                }
            }
        }
    }
}
