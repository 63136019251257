.md-form > label {
    color: $secondary-color;
}

.react-datetime-picker__inputGroup {
    input {
        &:first-child {
            display: none !important;
        }
    }
}

.form-control {
    color: $primary-color !important;
}
