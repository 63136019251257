@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.altTextGroup {
  .altTextError {
    color: $red-color;
    font-family: $font-book-regular, sans-serif;
  }
}