@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.productPaper {
}

.product {
    .editProduct {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .editProduct:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .fileInput {
        display: none;
    }

    .imgCard {
        height: 250px;
    }

    .uploadImg {
        color: $white-color;
        background-color: $red-color;
        cursor: pointer;
        width: 100%;
        margin: 0;
    }

    .actionArea {
        height: 15rem;
    }

    .uploadImg:hover {
        color: $white-color;
        background-color: #b82b2b;
        cursor: pointer;
    }

    .addProductForm {
        .typography {
            color: $black-deep-color;
            font-family: $font-medium;
        }

        .inputGroup {
            .formControl {
                .error_span {
                    color: $red-color;
                    font-family: $font-book-regular, sans-serif;
                }
            }
        }
    }

    .bottomNavigation {
        display: flex;
        float: right;
        .saveProduct {
            color: $white-color;
            background-color: $red-color;
            font-family: $font-book-regular, sans-serif;
        }

        .saveProduct:hover {
            text-decoration: none;
            background-color: #b82b2b;
            font-family: $font-book-regular, sans-serif;
        }

        .saveProduct:disabled {
            color: #666565;
            background-color: $white-color;
            cursor: not-allowed;
        }
    }
}
