@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.duplicateButton {
    position: relative;
    transform: translateX(-290px) translateY(2px);
}

.duplicateIcon {
    fill: #757575;
    width: 2rem;
    height: 2rem;
}
