@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.login {
    width: 100%;
    height: 100%;

    .loginContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 5% 0;

        .title {
            @include fontSizes(46px);
            font-weight: bold;
            margin: 2rem 0 1rem 0;
            color: $black-low-color;
            font-family: $font-black-regular, sans-serif;
        }

        .verifiedTitle {
            @include fontSizes(22px);
            font-weight: 600;
            margin-bottom: 1rem;
            color: green;
            font-family: $font-black-regular, sans-serif;
        }

        .loginForm {
            margin-top: 4rem;
            width: 50%;
            max-width: 420px;
        }

        .loginForm .submitBtn {
            color: $white-color;
            background-color: $red-color;
            cursor: default;
        }

        .loginForm .submitBtn:disabled {
            color: $button-disabled;
            background-color: $white-color;
            cursor: not-allowed;
        }

        .loginForm .submitBtn:hover {
            color: $black-low-color;
            background-color: $white-color;
        }

        .formControl .error_span {
            color: $red-color;
            font-family: $font-book-regular, sans-serif;
        }

        .formControl .formLabel {
            font-family: $font-book-regular, sans-serif;
        }

        .formControl .formInput {
            font-family: $font-book-regular, sans-serif;
            text-decoration: none;
        }
    }
}
