.circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 6px;
    cursor: pointer;
}

.selected {
    border: 2px solid #1c2fd9;
}
