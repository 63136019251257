@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.reviews {
    .addReviews {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
        display: flex;
    }

    .addReviews:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .ratingStarFilled {
        color: $red-color;
    }

    .ratingStarEmpty {
        color: transparent;
        background-color: transparent;
        stroke: black;
        stroke-width: 1px;
    }

    .blockUser {
        padding: 8px 16px;
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .blockUser:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .wideColumn {
        min-width: 150px;
    }

    .selectableColumn:hover {
        cursor: pointer;
    }

    .reviewsText {
        color: #4051b5;
        margin: 0;
        font-size: 14px;
    }

    .selectableColumn {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .formInput {
        min-width: 12rem;
    }

    .newButton {
        width: 4rem;
        height: 4rem;
    }
}
