@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.categoryTag {
    .addCategoryTag {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
        display: flex;
    }

    .addCategoryTag:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .editCategoryTag {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .editCategoryTag:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }
}

.addCategoryTagForm {
    .inputGroup {
        .formControl {
            .error_span {
                color: $red-color;
                font-family: $font-book-regular, sans-serif;
            }
        }
    }

    .inputGroupCenter {
        margin: 0 2rem 2rem 2rem;
        min-width: 15rem;
    }

    .inputGroupRight {
        margin: 0 2rem 2rem 2rem;
        min-width: 15rem;
    }

    .selectGroup {
        margin-left: auto;
        margin-right: auto;
        min-width: 15rem;
    }

    .saveCategoryTag {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
        display: flex;
    }

    .saveCategoryTag:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .saveCategoryTag:disabled {
        color: #666565;
        background-color: $white-color;
        cursor: not-allowed;
    }
}
