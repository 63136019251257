@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.paper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.5rem;

    .area {
        padding: 1rem;
        width: 100%;
        border-radius: 0.25rem;
        border: 2px solid $black-light-color;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;

        hr {
            width: 100%;
            border: 1px solid $black-light-color;
            border-radius: 0.25rem;
        }

        h2 {
            font-size: 1.5em;
        }

        .filterOption {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            flex-wrap: wrap;
            gap: 1rem;
            margin-bottom: 0.5rem;
        }

        .dateContainer {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;

            .date {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 0.25rem;
            }
        }
    }

    .filterArea {
        gap: 0.25rem;
    }

    .queryOptionsArea {
        gap: 0.25rem;
        padding: 0.5rem;
    }

    .accordion {
        .accordionSummary {
            display: flex;
            flex-direction: column;

            .queryHeader {
                margin-bottom: 0.1rem;
            }

            .queryDescription {
                margin-top: 0;
                color: $black-light-color;
                margin-bottom: 0rem;
            }
        }
    }

    .resultArea {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        table {
            width: 100%;
            border-collapse: collapse;
            border: 1px solid $black-light-color;
            border-radius: 0.25rem;
            table-layout: fixed;

            td,
            th {
                padding: 0.5rem;
                border: 1px solid $black-light-color;
                border-radius: 0.25rem;
            }

            td:nth-of-type(2),
            th:nth-of-type(2) {
                width: 96px;
            }
        }
    }

    .globalFilters {
        margin-bottom: 1rem;
    }

    .formControl {
        width: 300px;
    }
}
