@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.addTutorialStepper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.deleteIcon {
    position: absolute;
    right: 5px;
    z-index: 500;
}

.topMargin {
    margin-top: 2rem;
}

.tutorial {
    .addTutorialStepper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    .editTutorial {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .editTutorial:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .fileInput {
        display: none;
    }

    .imgCard {
        height: 250px;
    }

    .uploadImg {
        color: $white-color;
        background-color: $red-color;
        cursor: pointer;
        width: 100%;
        margin: 0;
    }

    .uploadImg:hover {
        color: $white-color;
        background-color: #b82b2b;
        cursor: pointer;
    }
    .actionArea {
        height: 15rem;
    }
    .addTutorialForm {
        .inputGroup {
            .formControl {
                .error_span {
                    color: $red-color;
                    font-family: $font-book-regular, sans-serif;
                }
            }
        }
    }

    .rte {
        height: 250px;
    }

    .dialog {
        height: 18rem;
        padding-left: 10px;
        padding-top: 10px;
        overflow-y: scroll;
        overflow-wrap: anywhere;
        text-align: initial;
        font-size: 1.2rem;
    }

    .mediaContainer {
        margin-top: 5rem;
    }

    .table {
    }
}

.bottomNavigation {
    .bottomNavigationButton {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
        float: right;
    }

    .bottomNavigationButton:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .bottomNavigationButton:disabled {
        color: #666565;
        background-color: $white-color;
        cursor: not-allowed;
    }
}
