@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.buttonRow {
    display: flex;
    margin: 8px 0;
    > * {
        margin: 0px 8px;
    }

    .backButton {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
        height: fit-content;
    }

    .backButton:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }
}
