.customTableData {
    padding: 16px 0 16px 16px;
    text-align: left;
}

.customTableData:first-child {
    padding-left: 16px;
    padding-right: 0;
}

.customTableRow {
    border-bottom: 1px solid #c4c4c4;
    cursor: pointer;
}

.customTableRow:hover {
    background-color: #00000014;
}

.customTableRowSelected {
    background-color: #00000014;
}

.customTableRowSelected:hover {
    background-color: #f5005714;
}
