@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.tutorial {
    .addTutorial {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
        display: flex;
    }

    .addTutorial:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .editTutorial {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .editTutorial:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }
}
