@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.orders {
    .voucherDiv {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 1rem;

        .vouhcersLeftText {
            margin: 0 1rem;
            font-family: $font-book-regular, sans-serif;
            font-size: 18px;
        }

        .voucherWarning {
            font-size: 18px;
            color: #b82b2b;
        }
    }
}
