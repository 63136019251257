@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';
@import './src/app/shared/styles/global.module';

.buttonRed {
    color: $white-color !important;
    background-color: $red-color !important;
    font-family: $font-book-regular, sans-serif !important;
    height: fit-content !important;
}

.buttonRed:hover {
    text-decoration: none !important;
    background-color: #b82b2b !important;
    font-family: $font-book-regular, sans-serif !important;
}

.buttonWhite {
    color: black !important;
    background-color: $white-color !important;
    border: $red-color solid 1px !important;
    font-family: $font-book-regular, sans-serif !important;
}

.buttonWhite:hover {
    text-decoration: none !important;
    color: $red-color !important;
    font-family: $font-book-regular, sans-serif !important;
}

.buttonRed:disabled,
.buttonWhite:disabled {
    color: $button-disabled !important;
    background-color: $white-color !important;
    border: $button-disabled solid 1px !important;
}

.blurBackground {
    position: fixed;
    z-index: 119;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
}

.stepDialog {
    position: absolute;
    z-index: 120;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    min-height: 640px;
    background-color: white;
    box-shadow: 0 0 4px black;
    border-radius: 0.5rem;

    .header {
        width: 100%;
        height: 64px;
        border-bottom: 1px solid gray;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 32px;
        font-size: 16px;
        font-family: $font-black;
    }

    .selectStepType {
        height: calc(640px - 64px);
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: center;
        gap: 32px;
    }

    .closeButton {
        width: 32px;
        height: 32px;
        z-index: 200;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(16px, -16px);
        background-color: white;
        box-shadow: 0 0 4px black;
    }

    .closeButton:hover {
        background-color: $red-color;
        box-shadow: 0 0 4px $red-color;
    }

    .closeButton:hover svg {
        color: white !important;
    }

    .stepTypeCard {
        width: 30%;
        height: 50%;
        border-radius: 0.25rem;
        border: 1px solid black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .icon {
            height: 128px;
            scale: 0.9;
            transition: all 0.2s ease-in-out;
        }

        span {
            font-size: 16px;
            font-family: $font-black;
        }
    }

    .stepTypeCard:hover .icon {
        scale: 1.1;
    }

    .stepTypeCard:hover {
        box-shadow: 0 0 5px gray;
        background-color: #fafafa;
    }

    .normalTextEditorWrapper {
        height: calc(640px - 90px);
    }

    .dashboardTextEditorWrapper {
        height: calc(640px - 164px);
    }
}

.descStepper span {
    font-family: $font-bold;
}

.addCookingStepsForm {
    min-height: calc(640px - 96px);
    width: 100%;
}

.errorText {
    color: $red-color;
    font-family: $font-book-regular, sans-serif;
}

.radioButton {
    color: $red-color !important;
}

.buttonWrapper {
    position: absolute;
    bottom: 1.5rem;
    right: 2rem;
}

.adornmentTouched {
    color: black;

    g {
        fill: black;
        color: black;
    }

    span {
        color: black;
    }
}

.adornmentUntouched {
    color: #a6a6a6ed;

    g {
        fill: #a6a6a6ed;
        color: #a6a6a6ed;
    }

    span {
        color: #a6a6a6ed;
    }
}

.formInput {
    width: calc(100% - 2rem);
    margin-top: 1rem !important;
}

.timerTempToggleBar {
    width: 100%;
    padding: 1rem 0;
    border: 1px solid #a6a6a6ed;
    cursor: pointer;
    font-size: 16px;
    font-family: $font-black;
}

.selectedSetting {
    background-color: #b82b2b;
    color: white;
}

.descIncompleteDialog {
    height: 640px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: $font-book-regular, sans-serif;
    font-size: 1.25em;
    font-weight: 600;
    line-height: 8px;
}

.descIncompleteDialog button {
    margin: 16px 16px;
}

.pointerInput input {
    cursor: pointer !important;
}
