@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.headerButtonWrapper {
    @include flex();
    @include justifyContent(flex-end);
    flex-wrap: wrap;
    padding: 0 !important;
}

.reviewItemWrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .reviewNotificationNumberWrapper {
        position: relative;
        float: right;
        margin-left: 12px;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: white;

        .reviewNotificationNumber {
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            margin-top: auto;
            text-align: center;
            color: red;
        }
    }
}
