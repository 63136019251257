@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';
@import './src/app/shared/styles/global.module';

.bottomNavigation {
    .bottomNavigationButton {
        color: $white-color;
        background-color: $red-color;
        cursor: default;
        float: right;
    }

    .bottomNavigationButton:disabled {
        color: #666565;
        background-color: $white-color;
        cursor: not-allowed;
    }

    .bottomNavigationButton:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
        cursor: pointer;
    }
}

.addRecipe {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .addRecipePaper {
        flex-grow: 1;

        .addRecipeForm {
            justify-content: center;

            .formControl {
                max-width: 300px;
                flex: 0 0 8rem;

                .uploadCard {
                }

                .imgCard {
                    height: 250px;
                }

                .uploadImg {
                    color: $white-color;
                    background-color: $red-color;
                    cursor: pointer;
                    width: 100%;
                }

                .actionArea {
                    height: 15rem;
                }
            }

            .inputGroup {
                margin: 0 2rem 2rem 2rem;
                flex: 0 0 8rem;
            }

            .fileInput {
                display: none;
            }

            .selectGroup {
                margin-left: auto;
                margin-right: auto;
                min-width: 15rem;
            }
        }
    }

    .addCookingStepsForm {
        flex-direction: row;
        flex: 1;

        .cookingStepsTable {
            width: calc(100vw - 320px);

            .editAction {
                color: $white-color;
                background-color: $red-color;
                cursor: default;
                float: right;
            }

            .editAction:disabled {
                color: #666565;
                background-color: $white-color;
                cursor: not-allowed;
            }

            .editAction:hover {
                text-decoration: none;
                background-color: #b82b2b;
                font-family: $font-book-regular, sans-serif;
                cursor: pointer;
            }
        }

        .formControl {
            margin-bottom: 2rem;
            margin-right: 2rem;
            min-width: 14rem;
        }

        .richTextEditor {
            flex: 1;
        }

        .dialog {
            height: 18rem;
            padding-left: 10px;
            padding-top: 10px;
            overflow-y: scroll;
            overflow-wrap: anywhere;
            text-align: initial;
            font-size: 1.2rem;
        }
    }

    .inputGroup {
        margin-left: 1rem;
        min-width: 15rem;
        float: left;
    }

    .selectGroup {
        margin-left: auto;
        margin-right: auto;
        min-width: 15rem;
        float: right;
    }
}

.warningDialog {
    font-size: 1rem;
    color: #ff3c00ad;
}

.dialogTitle {
    display: flex;
    flex-direction: column;
}

.addRecipeForm .submitBtn {
    color: $white-color;
    background-color: $red-color;
    cursor: default;
    float: right;
    margin-right: 25%;
}

.addRecipeForm .submitBtn:disabled {
    color: #666565;
    background-color: $white-color;
    cursor: not-allowed;
}

.addRecipeForm .submitBtn:hover {
    text-decoration: none;
    background-color: #b82b2b;
    font-family: $font-book-regular, sans-serif;
    cursor: pointer;
}

.error_span {
    color: $red-color;
    font-family: $font-book-regular, sans-serif;
}

.blurBackground {
    position: fixed;
    z-index: 119;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
}

.addIngredients {
    font-family: $font-medium;
    min-height: calc(100vh - 220px);
    height: 100%;
    position: relative;

    .addIngredientDialog {
        z-index: 120;
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .addIngredientForm {
        margin-top: auto;

        .formControl {
            display: flex;
            flex-grow: 1;
        }

        .inputGroup {
            min-width: 15rem;
        }

        .selectGroup {
            margin-left: auto;
            margin-right: auto;
            min-width: 15rem;
        }
    }
}

.headerLabel {
    margin-top: 1rem;
    margin-left: 1rem;
    width: 100%;
    height: 2.5rem;
    font-weight: bold;
    text-align: left;
    font-size: 1.2em;
}

.radioButton {
    color: $red-color !important;
}

.listStyle {
    width: 100%;
    min-width: 700px;
    max-width: 100%;
    max-height: 400px;
    overflow: auto;
}

.searchBarStyle {
    width: 100%;
}

.cookingSteps {
    position: relative;
    width: 100%;
    height: 80%;
    min-height: calc(100vh - 200px);

    .addFirstStepContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;

        .addFirstStepIconButton {
            height: 128px;
            width: 128px;

            .addFirstStepIcon:hover g g g g g {
                stroke: $red-color;
                fill: $red-color;
            }

            .addFirstStepIcon:hover g g g g use {
                stroke: $red-color;
            }
        }

        .addFirstStepIconButton:hover {
            background-color: transparent;
        }
    }

    .standardButton {
        color: $white-color;
        background-color: $red-color;
        cursor: default;
        float: right;
    }

    .standardButton:disabled {
        color: #666565;
        background-color: $white-color;
        cursor: not-allowed;
    }

    .standardButton:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
        cursor: pointer;
    }

    .addButtonRow {
        height: 48px;
        width: 100%;
        display: flex;
        justify-content: left;
    }

    .incompleteWarningContainer {
        display: flex;
        align-items: center;

        .incompleteWarning {
            width: 24px;
            height: 24px;
            background-color: $red-color;
            border-radius: 100%;
            color: white;
            font-weight: bolder;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 1rem;
        }
    }
}

.cookingSteps:after {
    display: table;
    content: '';
    clear: both;
}
