@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.footer {
    margin-top: calc(5% + 60px);
    bottom: 0;
    font-family: $font-bold, sans-serif;
}
