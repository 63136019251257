@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.selection {
    align-items: center;
    justify-content: center;

    .addSelectionStepperStepper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    .editSelection {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .editSelection:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .addSelectionForm {
        margin: auto;
        width: 70%;

        .inputGroup {
            max-width: 80rem;

            .formControl {
                .error_span {
                    color: $red-color;
                    font-family: $font-book-regular, sans-serif;
                }
            }
        }

        .ratingStarFilled {
            color: $red-color;
        }

        .ratingStarEmpty {
            color: transparent;
            background-color: transparent;
            stroke: black;
            stroke-width: 1px;
        }

        .adornmentTouched {
            color: black;

            g {
                fill: black;
                color: black;
            }

            span {
                color: black;
            }
        }

        .adornmentUntouched {
            color: #a6a6a6ed;

            g {
                fill: #a6a6a6ed;
                color: #a6a6a6ed;
            }

            span {
                color: #a6a6a6ed;
            }
        }

        .cancelChipIcon {
            color: #a6a6a6;
            background: transparent;
            background: radial-gradient(circle, #e0e0e0 0%, #e0e0e0 50%, #ffffff00 51%, #ffffff00 100%);
        }
    }

    .valueTagsForm {
        margin: auto;
        padding: 1rem;
        padding-top: 2rem;
        width: 70%;
    }
}

.bottomNavigation {
    .bottomNavigationButton {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
        float: right;
    }

    .bottomNavigationButton:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .bottomNavigationButton:disabled {
        color: #666565;
        background-color: $white-color;
        cursor: not-allowed;
    }
}

.pinnedRecipes {
    margin: 0.25rem 0.25rem;
}

.noRecipesLabel {
    width: 100%;
    text-align: left;
    margin: 0.5rem 0.5rem;
    font-size: 0.75em;
}
