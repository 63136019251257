@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.equipment {
    .addEquipment {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
        display: flex;
    }

    .addEquipment:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .editEquipment {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .editEquipment:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }
}

.addEquipmentForm {
    .inputGroup {
        .formControl {
            .error_span {
                color: $red-color;
                font-family: $font-book-regular, sans-serif;
            }
        }
    }

    .inputGroupCenter {
        margin: 0 2rem 2rem 2rem;
        min-width: 15rem;
    }

    .inputGroupRight {
        margin: 0 2rem 2rem 2rem;
        min-width: 15rem;
    }

    .selectGroup {
        margin-left: auto;
        margin-right: auto;
        min-width: 15rem;
    }

    .saveEquipment {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
        display: flex;
    }

    .saveEquipment:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .saveEquipment:disabled {
        color: #666565;
        background-color: $white-color;
        cursor: not-allowed;
    }
}
