@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.ambassadorPaper {
    padding: 1rem;
    .editAmbassador {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .editAmbassador:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }
}

.toggleButtonGroup {
    width: 400px;
    height: fit-content;
}

.ambassador {
    display: flex;

    .editAmbassador {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .editAmbassador:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .fileInput {
        display: none;
    }

    .imgCard {
        height: 250px;
    }

    .uploadImg {
        color: $white-color;
        background-color: $red-color;
        cursor: pointer;
        width: 100%;
        margin: 0;
    }

    .actionArea {
        height: 15rem;
    }

    .uploadImg:hover {
        color: $white-color;
        background-color: #b82b2b;
        cursor: pointer;
    }

    .addAmbassadorForm {
        .typography {
            color: $black-deep-color;
            font-family: $font-medium;
        }

        .inputGroup {
            .formControl {
                .error_span {
                    color: $red-color;
                    font-family: $font-book-regular, sans-serif;
                }
            }
        }
    }

    .bottomNavigation {
        .saveAmbassador {
            color: $white-color;
            background-color: $red-color;
            font-family: $font-book-regular, sans-serif;
            float: right;
        }

        .saveAmbassador:hover {
            text-decoration: none;
            background-color: #b82b2b;
            font-family: $font-book-regular, sans-serif;
        }

        .saveAmbassador:disabled {
            color: #666565;
            background-color: $white-color;
            cursor: not-allowed;
        }
    }

    .rte {
        height: 250px;
    }

    .dialog {
        height: 18rem;
        padding-left: 10px;
        padding-top: 10px;
        overflow-y: scroll;
        overflow-wrap: anywhere;
        text-align: initial;
        font-size: 1.2rem;
    }

    .addAmbassador {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
        display: flex;
    }

    .addAmbassador:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .editAmbassador {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .editAmbassador:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }
}

.influencerFeed {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .addButton {
        align-self: flex-start;
    }
}

.dialog {
    .addForm {
        margin: 4rem 2rem;
    }

    .formControl {
        width: 100%;

        .error_span {
            color: $red-color;
            font-family: $font-book-regular, sans-serif;
        }
    }

    .redButton {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .redButton:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .redButton:disabled {
        color: #666565;
        background-color: $white-color;
        cursor: not-allowed;
    }

    .dialogSaveButton {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
    }
}
