.dialog {
    height: 18rem;
    padding-left: 10px;
    padding-top: 10px;
    overflow-y: scroll;
    overflow-wrap: anywhere;
    text-align: initial;
    font-size: 1.2rem;
    border: 1px solid black;
}
.label {
    position: absolute;
    left: calc(1 * 5%);
    top: calc(5 * 5%);
}
