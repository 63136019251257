@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/trumps';
@import '../../../../assets/styles/tools';

.import .importRecipe {
    color: $white-color;
    background-color: $red-color;
    font-family: $font-book-regular, sans-serif;
}

.import .importRecipe:hover {
    text-decoration: none;
    background-color: #b82b2b;
    font-family: $font-book-regular, sans-serif;
}

.input {
    display: none;
}
