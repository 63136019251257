@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';
@import './src/app/shared/styles/global.module';

.editorContainer {
    height: 100%;
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .buttonWrapper {
        position: absolute;
        bottom: 1rem;
        right: 1rem;

        .buttonRed {
            color: $white-color;
            background-color: $red-color;
            font-family: $font-book-regular, sans-serif;
            height: fit-content;
        }

        .buttonRed:hover {
            text-decoration: none;
            background-color: #b82b2b;
            font-family: $font-book-regular, sans-serif;
        }

        .buttonWhite {
            color: black;
            background-color: $white-color !important;
            border: $red-color solid 1px;
            font-family: $font-book-regular, sans-serif;
        }

        .buttonWhite:hover {
            text-decoration: none;
            background-color: grey;
            font-family: $font-book-regular, sans-serif;
        }
    }

    .headerLabel {
        margin-top: 1rem;
        margin-left: 1rem;
        height: 48px;
        font-weight: bold;
        text-align: left;
        font-size: 1.2em;
    }
}

.textEditor {
    margin-left: 0;
    padding-left: 0;
    height: 100%;
    width: 100%;

    .editor {
        margin-top: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
    }

    .innerEditorContainer {
        padding-top: 0;
    }

    .toolbar {
        width: 100%;
        max-width: 100%;
    }
}

.languageWrapper {
    display: flex;
    flex-wrap: 'nowrap';
}
