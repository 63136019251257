@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.modal {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);

    .modalImage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 50%;
        max-height: 50%;
        width: auto;
        height: auto;
        border-radius: 16px;
    }
}
