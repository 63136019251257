@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.productPaper {
}

.product {
    .editProduct {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .editProduct:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .fileInput {
        display: none;
    }

    .imgCard {
        height: 250px;
    }

    .uploadImg {
        color: $white-color;
        background-color: $red-color;
        cursor: pointer;
        width: 100%;
        margin: 0;
    }

    .actionArea {
        height: 15rem;
    }

    .uploadImg:hover {
        color: $white-color;
        background-color: #b82b2b;
        cursor: pointer;
    }

    .formHeader {
        width: 100%;
        text-align: left;
        font-size: 1rem;
    }

    .formControl {
        width: 100%;

        .error_span {
            color: $red-color;
            font-family: $font-book-regular, sans-serif;
        }
    }

    .addProductForm {
        .typography {
            color: $black-deep-color;
            font-family: $font-medium;
        }
    }

    .bottomNavigation {
        display: flex;
        float: right;
    }

    .saveProduct {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;

        .shuffleButton {
            margin-left: 8px !important;
            margin-bottom: 8px;
        }
    }

    .saveProduct:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .saveProduct:disabled {
        color: #666565;
        background-color: $white-color;
        cursor: not-allowed;
    }

    .flexStartWrapper {
        display: flex;
        justify-content: flex-start;
    }

    .preview {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        margin: 0 auto;
        width: 345px;
        height: 155px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        position: relative;

        .content {
            width: 190px;
            margin: 15px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        .previewButton {
            justify-self: end;
            width: fit-content;
            padding: 6px 15px;
            margin: 10px 0 0 0;
            color: $white-color;
            background-color: $red-color;
            font-family: $font-bold, sans-serif;
            font-weight: 800;
            line-height: 16.2px;
        }

        .title {
            margin: 0 0 auto 0;
            font-family: $font-black;
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 16px;
            white-space: pre-line;
        }

        .description {
            margin: 0;
            font-family: $font-light;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            white-space: pre-line;
        }

        .closeIcon {
            position: absolute;
            padding: 0;
            margin: 0;
            width: 20.5px;
            height: 20.5px;
        }

        .closeIconRight {
            top: 2.75px;
            right: 2.75px;
        }

        .closeIconLeft {
            top: 2.75px;
            left: 2.75px;
        }
    }
}

.couponDialog {
    padding: 1rem;

    .item {
        // text-align: center;
    }
    .title {
        text-align: center;
    }
}
