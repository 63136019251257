@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.generalContainer {
    margin-top: 4rem;
}

.formButton {
    color: $white-color !important;
    background-color: $red-color !important;
    cursor: default !important;
    margin-right: 0.5em !important;
}

.formButton:disabled {
    color: #666565 !important;
    background-color: $white-color !important;
    cursor: not-allowed !important;
}

.formButton:hover {
    text-decoration: none !important;
    background-color: #b82b2b !important;
    font-family: $font-book-regular, sans-serif !important;
    cursor: pointer !important;
}
