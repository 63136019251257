@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';
@import './src/app/shared/styles/global.module';

.dialog {
    display: flex;
    flex-direction: column;
    .cropWrapper {
        position: relative;
        width: 100%;
        height: 600px;
    }

    .saveButton {
        margin: 1rem;
        align-self: flex-end;
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .saveButton:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .saveButton:disabled {
        color: #666565;
        background-color: $white-color;
        cursor: not-allowed;
    }
}
