@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.troubleshootInfo {
    max-width: 64rem;
}

.paper,
.dialog {
    .redButton {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .redButton:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .redButton:disabled {
        color: #666565;
        background-color: $white-color;
        cursor: not-allowed;
    }

    .bottomNavigation {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .topLeftNavigation {
        display: flex;
        justify-content: flex-start;
    }

    .checkbox {
        color: green;

        &.Mui-checked {
            color: gold;
        }
    }
}

.container {
    .edit {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .edit:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .fileInput {
        display: none;
    }

    .imgCard {
        height: 250px;
    }

    .uploadImg {
        color: $white-color;
        background-color: $red-color;
        cursor: pointer;
        width: 100%;
        margin: 0;
    }

    .actionArea {
        height: 15rem;
    }

    .uploadImg:hover {
        color: $white-color;
        background-color: #b82b2b;
        cursor: pointer;
    }

    .formControl {
        width: 100%;

        .error_span {
            color: $red-color;
            font-family: $font-book-regular, sans-serif;
        }
    }

    .addForm {
        .typography {
            color: $black-deep-color;
            font-family: $font-medium;
        }
    }

    .primaryStepSelect {
        width: 100%;
        max-width: 420px;
        text-align: left;
        margin-left: 0;
        align-self: flex-start;
        justify-self: flex-start;
    }

    .primaryStepSelectLabel {
        margin-top: 0.5rem;
        text-align: right;
    }
}

.chooseStepCardWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 24rem;

    .chooseStepCard {
        height: 20rem;
        width: 15rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
        border: 1px solid black;
        padding: 10px;
        transition: background-color 0.3s ease;
        cursor: pointer;

        &:hover {
            background-color: #dbdbdb;
        }

        .icon {
            display: block;
            font-size: 24px;
            margin-bottom: 1rem;
        }

        .label {
            font-size: 14px;
            margin: 0;
            font-weight: bold;
            text-align: center;
        }
    }
}

.dialog {
    .dialogContents {
        overflow-y: auto;

        .dialogSaveButton {
            margin: 1rem 0 0 auto !important;
        }
    }

    .languageSelect {
        margin: 1rem;
        max-width: fit-content;
    }
    .languageWrapper {
        display: flex;
        flex-wrap: 'nowrap';
        width: 12rem;
    }

    .addForm {
        margin: 2rem;

        .typography {
            color: $black-deep-color;
            font-family: $font-medium;
        }

        .formControl {
            width: 100%;

            .error_span {
                color: $red-color;
                font-family: $font-book-regular, sans-serif;
            }
        }
    }

    .pixelated {
        image-rendering: pixelated;
    }
}
