$gotham-path: '../../assets/fonts/Gotham';

@font-face {
    font-family: 'Gotham Black Regular';
    src: url('#{$gotham-path}/GothamBlackRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Bold';
    src: url('#{$gotham-path}/GothamBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Bold Italic';
    src: url('#{$gotham-path}/GothamBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Book Italic';
    src: url('#{$gotham-path}/GothamBookItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Book Regular';
    src: url('#{$gotham-path}/GothamBookRegular.otf') format('otf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Light';
    src: url('#{$gotham-path}/GothamLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Medium';
    src: url('#{$gotham-path}/GothamMedium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
