@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';

.recipe {
}

.recipeBody {
    font-family: $font-bold, sans-serif;
}

.recipe .actionButtons {
    display: flex;

    .addRecipe {
        color: $white-color;
        background-color: $red-color;
        font-family: $font-book-regular, sans-serif;
    }

    .addRecipe:hover {
        text-decoration: none;
        background-color: #b82b2b;
        font-family: $font-book-regular, sans-serif;
    }

    .addRecipe:disabled {
        color: #666565;
        background-color: $white-color;
        cursor: not-allowed;
    }
}

.recipe .editRecipe {
    color: $white-color;
    background-color: $red-color;
    font-family: $font-book-regular, sans-serif;
    display: flex;
}

.recipe .editRecipe:hover {
    text-decoration: none;
    background-color: #b82b2b;
    font-family: $font-book-regular, sans-serif;
}
