@import './src/assets/styles/fonts.scss';
@import './src/assets/styles/variables.scss';
@import './src/assets/styles/trumps.scss';
@import './src/assets/styles/tools.scss';
@import './src/app/shared/styles/global.module';

.card {
    .fileInput {
        display: none;
    }

    .imgCard {
        height: 250px;
    }

    .uploadImg {
        color: $white-color;
        background-color: $red-color;
        cursor: pointer;
        width: 100%;
        margin: 0;
    }

    .actionArea {
        height: 15rem;
    }

    .uploadImg:hover {
        color: $white-color;
        background-color: #b82b2b;
        cursor: pointer;
    }
}
